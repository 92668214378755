<template>
  <SimpleTableForm
    :id="id"
    title="Create StructuralDiversity"
    :table-config="tableConfig"
    :return-route="adminRoute"
    :server-type="serverType"
    @created="$emit('created')"
  />
</template>

<script>

import { structuralDiversityForm } from './constants'
import SimpleTableForm from '@/components/form/SimpleTableForm'

export default {
  name: 'StructuralDiversityForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableConfig: structuralDiversityForm,
      serverType: 'structural_diversities',
      adminRoute: '/admin/structuralDiversities'
    }
  }
}
</script>
